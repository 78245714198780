<template>
  <div>
    <b-row>
      <b-col
        v-if="resources"
        cols="12"
      >
        <b-card
          title="NetWorker Resources"
        >
          <json-viewer
            :value="resources"
            :expand-depth="1"
            copyable
            sort
          />
        </b-card>
      </b-col>
      <b-col
        v-if="devices.length > 0"
        cols="12"
      >
        <b-card
          :title="`Configured devices (${devices.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Enabled</th>
                  <th>Media family</th>
                  <th>Media type</th>
                  <th>Write enabled</th>
                  <th>Readonly</th>
                  <th>Cons. errors</th>
                  <th>Max. cons. errors</th>
                  <th>Cleaning required</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(device, index) in devices"
                  :key="`device-${index}`"
                >
                  <td>{{ device.name }}</td>
                  <td>{{ device.enabled }}</td>
                  <td>{{ device.mediaFamily }}</td>
                  <td>{{ device.mediaType }}</td>
                  <td>{{ device.writeEnabled }}</td>
                  <td>{{ device.readOnly }}</td>
                  <td>{{ device.consecutiveErrors }}</td>
                  <td>{{ device.maxConsecutiveErrors }}</td>
                  <td>{{ device.cleaningRequired }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="mediaVolumes.length > 0"
        cols="12"
      >
        <b-card
          :title="`Media volumes (${mediaVolumes.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Volume</th>
                  <th>Family</th>
                  <th>Pool</th>
                  <th>Status</th>
                  <th>Expires</th>
                  <th>Written</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(mediaVolume, index) in mediaVolumes"
                  :key="`mediavolume-${index}`"
                >
                  <td>{{ mediaVolume.volume }}</td>
                  <td>{{ mediaVolume.family }}</td>
                  <td>{{ mediaVolume.pool }}</td>
                  <td>{{ mediaVolume.volFlagsDescription }}</td>
                  <td>{{ mediaVolume.expires }}</td>
                  <td>{{ mediaVolume.written }}</td>
                  <td>{{ mediaVolume.location }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="vProxies.length > 0"
        cols="12"
      >
        <b-card
          :title="`vProxies (${vProxies.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Enabled</th>
                  <th>Max. Hotadd Session</th>
                  <th>Max. NBD Sessions</th>
                  <th>Encrypt NBD Sessions</th>
                  <th>Fallback to NDB for idle disk</th>
                  <th>vCenter</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(vProxie, index) in vProxies"
                  :key="`vProxie-${index}`"
                >
                  <td>{{ vProxie.name }}</td>
                  <td>{{ vProxie.enabled }}</td>
                  <td>{{ vProxie.maxHotaddSessions }}</td>
                  <td>{{ vProxie.maxNbdSessions }}</td>
                  <td>{{ vProxie.encryptNbdSessions }}</td>
                  <td>{{ vProxie.fallbackNdb }}</td>
                  <td>{{ vProxie.vCenter }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<!-- eslint-disable no-param-reassign -->
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import JsonViewer from 'vue-json-viewer'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    JsonViewer,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    devices() {
      if (this.asup.details && this.asup.details.devices) {
        return this.asup.details.devices
          .concat()
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      }

      return []
    },
    mediaVolumes() {
      if (this.asup.details && this.asup.details.mediaVolumes) {
        return this.asup.details.mediaVolumes
          .concat()
          .sort((a, b) => (a.volume > b.volume ? 1 : -1))
      }

      return []
    },
    vProxies() {
      // eslint-disable-next-line no-unused-vars
      const vProxies = []

      // eslint-disable-next-line no-unused-vars
      const nsrVmWareProxies = this.resources['NSR VMware proxy']
      if (nsrVmWareProxies) {
        // eslint-disable-next-line no-restricted-syntax, no-unused-vars
        for (const [key, value] of Object.entries(nsrVmWareProxies)) {
          if (value && value.length === 1 && key !== 'undefined') {
            vProxies.push({
              name: value[0].name,
              enabled: value[0].enabled,
              maxHotaddSessions: value[0]['maximum hotadd sessions'],
              maxNbdSessions: value[0]['maximum NBD sessions'],
              encryptNbdSessions: value[0]['encrypt NBD sessions'],
              fallbackNdb: value[0]['Fallback to NBD for IDE disk'],
              vCenter: value[0].Vcenter,
            })
          }
        }
      }
      return vProxies
    },
    resources() {
      if (this.asup.details && this.asup.details.res) {
        // const result = {}

        // create readable json structure of res (grouped by type, then by name)
        // remove prop "policy definition" because it´s duplicate of "policy definition parsed" (coming from parser for better readable)
        const result = this.asup.details.res.reduce((p, c) => {
          const { type, name } = c
          p[type] = p[type] || {}

          if (c['policy definition']) {
            delete c['policy definition']
          }

          let typeName = name
          if (!typeName) { // in case name has no value
            typeName = 'undefined'
          }

          p[type][typeName] = p[type][typeName] || []
          p[type][typeName].push(c)

          return p
        }, {})

        // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-unreachable
        for (const key in result['NSR Protection Policy']) {
          result['NSR Protection Policy'][key].forEach(protectionPolicy => {
            // Rename 'policy definition parsed' property to 'policy definition' in "NSR Protection Policy" object
            protectionPolicy['policy definition'] = protectionPolicy['policy definition parsed']
            delete protectionPolicy['policy definition parsed']

            if (protectionPolicy['policy definition']) {
            // Group policyWorkflows by workflowName
              protectionPolicy['policy definition'].policyWorkflows = protectionPolicy['policy definition'].policyWorkflows.reduce((p, c) => {
                const { workflowName } = c
                p[workflowName] = p[workflowName] || []

                // Group workflowActions by actionName
                const wfActions = {}
                if (c.workflowActions && Array.isArray(c.workflowActions)) {
                  c.workflowActions.forEach(wfAction => {
                    let typeName = wfAction.actionName
                    if (!typeName) { // in case name has no value
                      typeName = 'undefined'
                    }

                    wfActions[typeName] = wfActions[typeName] || []
                    wfActions[typeName].push(wfAction)
                  })
                }

                c.workflowActions = wfActions
                p[workflowName].push(c)

                // Reduce inside reduce did not work...
                /*
                p[workflowName].workflowActions = p[workflowName].workflowActions.reduce((ap, ac) => {
                  const { actionName } = ac
                  ap[actionName] = ap[actionName] || {}
                  ap[actionName] = ac

                  return ap
                })
              */

                return p
              }, {})
            }
          })
        }

        return result
      }

      return {}
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
